import React, { Component } from 'react';
import { connect } from "react-redux";
// import baadActions from '../../redux/actions/';

import HomeLink from '../HomeLink/homeLink';
import './humanActivities.scss';
import baadActions from '../../redux/actions/';
import PanelFooter from '../panelFooter/panelFooter';
import ReactHtmlParser from 'react-html-parser';
import constants from "../../utils/appConstant";

class Humanactivities extends Component {

    constructor(props) {
        super(props);
        var fromEventPage = false;
        var backgroundImage = '';
        var toExhibition = false;
        var exhibitionName = '';
        if (props.history.location.state) {
            if (props.history.action !== "POP" && props.history.location.state.fromEventPage ) {
                fromEventPage = true;
            }
            if (props.history.location.state.backgroundImage) {
                backgroundImage = props.history.location.state.backgroundImage;
            }
            if (props.history.location.state.toExhibition) {
                toExhibition = true;
                exhibitionName = props.history.location.state.exhibitionName;
            }
        }
        this.state = {
            isHovering: false,
            activeBgImage: '',
            imageLink: '',
            x: 0,
            y: 0,
            fromEventPage: fromEventPage,
            fromLink: false,
            backgroundImage: backgroundImage,
            secondPage: true,
            disableFunction: true,
            toExhibition: toExhibition,
            exhibitionName: exhibitionName,
            lineX: 0,
            lineY: 0,
            width: 0,
            exhibitionLink: '',
            footerSubtitle: ''
        }
        this.enableChangeBg = this.enableChangeBg.bind(this);
        this.timer = setTimeout(this.enableChangeBg, 1000);
        this.changePageState = this.changePageState.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.subpageRedirect = this.subpageRedirect.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        const footerLogoMobile = document.getElementById('footer_logo--mobile')
        footerLogoMobile && footerLogoMobile.addEventListener("click", this.subpageRedirect);
        this.props.mouseOut();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    changePageState() {
        setTimeout(() => {
            document.getElementsByClassName('left-main-div')[0].style.transitionTimingFunction = "cubic-bezier(.35,-0.4,.8,.7)";
            document.getElementById('imagesSlide').style.transitionTimingFunction = "cubic-bezier(.35,-0.4,.8,.7)";
            this.setState({
                secondPage: false
            });
        }, 100);
    }

    subpageRedirect = async () => {
        await new Promise(resolve => {
            this.props.getHumanPostDataBySlug(this.state.exhibitionLink, resolve)
        });
        this.state.exhibitionLink !== '' && this.props.history.push({
            pathname: '/h/' + this.state.exhibitionLink,
            state: { toExhibition:  this.state.toExhibition, exhibitionName: this.state.exhibitionName}
        });
    }

     componentDidUpdate(prevProps) {
        if (this.props.history.action === "POP" && String(prevProps.humanData) === '') {
            this.props.getHumanData();
        }
        /* @FIXME: workaround for loading the lines after you have entered the subpage directly and then pressed the logo */
        if (this.props.history.action === "PUSH" && String(prevProps.humanData) === '') {
            this.props.getHumanData();
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    enableChangeBg() {
        this.setState({
            disableFunction: false
        });
    }

    humanPostPage = async (slug) => {
        document.getElementsByClassName('page')[0].classList.remove('exit-Left');
        document.getElementsByClassName('page')[0].classList.add('exit-Up');
        await new Promise(resolve => {
            this.props.getHumanPostDataBySlug(slug, resolve)
        });
        document.getElementsByClassName('headerDiv')[0].style.padding = "58px 10px 0px";
        this.props.history.push({
            pathname: '/h/' + slug,
            state: { toExhibition:  this.state.toExhibition, exhibitionName: this.state.exhibitionName}
        });
    }

    moveToolTip = (e,i) => {
        let tooltip = document.getElementById("Tooltip" + i);
        var tooltipWidth = tooltip.offsetWidth;
        // var boxWidth = (window.innerWidth - 40) / this.props.humanData.length;
        let offsetRight = window.innerWidth - e.clientX;
        let offsetLeft = e.clientX;

        if ((tooltipWidth < offsetLeft) && (tooltipWidth + 40 < offsetRight)) {
            this.setState({
                x: e.clientX + 20,
                y: e.clientY
            });
            tooltip.style.width = 'fit-content';
        } else if ((tooltipWidth > offsetLeft) && (tooltipWidth + 40 < offsetRight)) {
            this.setState({
                x: e.clientX + 20,
                y: e.clientY
            });
            tooltip.style.width = '';
        } else if ((tooltipWidth< offsetLeft) && (tooltipWidth + 40 > offsetRight)) {
            this.setState({
                x: e.clientX - tooltipWidth - 40,
                y: e.clientY
            });
            tooltip.style.width = 'fit-content';
        } else {
            this.setState({
                x: e.clientX - tooltipWidth/2,
                y: e.clientY
            });
            tooltip.style.width = 'auto';
        }
    };

    handleGalleryScroll = async (e) => {
        const galleryList = document.querySelector('.humanActivitiesMainDiv');
        const lineCursor = document.getElementById('lineCursor').getBoundingClientRect();
        let items = [...document.querySelectorAll('.vertical-whiteLine')];
        const footerTitle = document.getElementsByClassName('footer__title');
        const footerSubtitle = document.querySelector('h2.footer__subtitle');
        let clones = [];

        if (!this.state.galleryClonesExecuted) {
            this.setState({
                galleryClonesExecuted: true
            })
        }

        items.forEach(item => {
            const verticalLinePos = item.getBoundingClientRect()
            const targetId = item.attributes['id'].value.replace('verticalLine', '')
            const artistName = this.props.humanData[targetId].acf.artist_name;
            const workTitle = this.props.humanData[targetId].acf.work_title;

            if (!this.state.galleryClonesExecuted) {
                let clone = item.cloneNode(true);
                clone.classList.add('clone');
                galleryList.appendChild(clone);
                clones.push(clone);
            }

            if (lineCursor.x - verticalLinePos.x
            < lineCursor.x < verticalLinePos.x
                ? verticalLinePos.width - 100 : lineCursor.width) {
                footerTitle[0].textContent = `${workTitle ? workTitle : 'NAME OF THE ARTWORK'}`
                footerSubtitle.textContent = artistName ? artistName : 'NAME OF THE ARTIST'
                this.setState({
                    imageLink: item.attributes['data-src'].value,
                    exhibitionLink: this.props.humanData[targetId].slug
                })
            }
        })

        if (galleryList.offsetWidth + galleryList.scrollLeft >= galleryList.scrollWidth) {
            galleryList.scrollLeft = (galleryList.scrollWidth / 2) - 368;
        } else if (galleryList.scrollLeft <= 0) {
            galleryList.scrollLeft = galleryList.scrollWidth / 2;
        }
    }

    showTooltipChangeBg = async (e, i) => {
        const footerSubtitle = document.querySelector('h2.footer__subtitle');

        this.setState({
            isHovering: !this.state.isHovering,
            activeBgImage: i,
        });

        let targetId = e.target.id;
        let isHovering = this.state.isHovering;

        if (!isHovering) {
            this.refs[targetId].classList.add("show-item");
            footerSubtitle.textContent = 'CLICK TO VIEW'
        } else {
            this.refs[targetId].classList.remove("show-item");
            footerSubtitle.textContent = ''
        }
    };
    cursorLine = (e) => {
        if (window.screen.width > 375) {
            this.setState({
                lineX: e.clientX
            });
        }
    }
    clickHomeLink = async () => {
        document.getElementsByClassName('page')[0].classList.remove('exit-Left');
        document.getElementsByClassName('page')[0].classList.add('exit-Right');

        await new Promise(resolve => {
            this.props.getPageDataBySlug('random-teaser', resolve)
        });

        let currentPath = this.props.history.location.pathname;
        this.props.history.push({
            pathname: '/' + constants.INTRO_URL,
            state: {
                beforePath: currentPath,
                fromHumanPage: true
            }
        });
    }

    render() {
        if (this.props.humanData.length === 0) {
            return null;
        }

        let bgUrl = "";
        if (this.state.imageLink !== '') {
            bgUrl = this.state.imageLink;
        }
        else {
            if (this.state.backgroundImage !== '') {
                bgUrl = this.state.backgroundImage
            } else {
                bgUrl = "this.props.humanData[0].acf.image_url";
            }
        }
        let transitionClass = 'enter-Right'
        if (this.state.fromEventPage) {
            transitionClass = 'enter-Down';
        }
        else if (this.state.fromLink) {
            transitionClass = 'enter-Left exit-Up';
        }

        this.props.humanData.sort((a, b) => a.acf.post_title.localeCompare(b.acf.post_title))

        return (
            <div className={"whatever-class home-page humanActivity-page page " + transitionClass}>
                <HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}></HomeLink>
                <div className="headerDiv" onScroll={(e) => this.handleGalleryScroll(e)}>
                    <div className="main-border position-relative animation-content h-100">
                        <div className="slide-home h-100">
                            <div id="linkData" className={"left-main-div main-part " + (this.state.secondPage ? 'secondLinkDiv' : 'FistLinkDiv')}>
                                <div id="linkdiv">
                                    {/* {this.categories()} */}
                                </div>
                            </div>
                            <div id="imagesSlide" className={"sliderStyle h-100 human-part " + (this.state.secondPage ? 'secondImagesSlide' : 'FistImagesSlide')} onMouseMove={(e) => this.cursorLine(e)}
                            >
                                <div className="h-100">
                                    {
                                        !this.state.secondPage ?
                                            <div style={{ backgroundImage: `url(${bgUrl})`, backgroundSize: "cover"}} className="imgStyle">
                                            </div>
                                            :
                                            <div className="h-100 position-relative">
                                                {this.state.width <= 375 &&
                                                    <div id="humanActivity-page__image" className="humanActivity-page__image humanActivity-page__image--active" style={{backgroundImage: `url(${bgUrl})`}}> </div>
                                                }
                                                <div id="parentDiv" className="d-flex humanActivitiesMainDiv">
                                                    {
                                                        this.props.humanData ? this.props.humanData.map((data, i) => {
                                                            return (
                                                                <>
                                                                    {this.state.width > 375
                                                                        ?
                                                                        <>
                                                                            {data.acf.image_url &&
                                                                            <div
                                                                                id="humanActivity-page__image"
                                                                                className={this.state.isHovering && this.state.activeBgImage === i ? 'humanActivity-page__image humanActivity-page__image--active' : 'humanActivity-page__image'}
                                                                                style={{backgroundImage: `url(${data.acf.image_url})`}}/>}
                                                                            <div
                                                                                onMouseOver={(e) => this.showTooltipChangeBg(e, i)}
                                                                                onMouseLeave={(e) => this.showTooltipChangeBg(e, i)}
                                                                                onMouseMove={(e) => this.moveToolTip(e, i)}
                                                                                id={"verticalLine" + i}
                                                                                className="vertical-whiteLine"
                                                                                onClick={() => this.humanPostPage(data.slug)}
                                                                                key={"humanVertical" + i}
                                                                                data-src={data.acf.image_url}>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <div
                                                                            id={"verticalLine" + i}
                                                                            className="vertical-whiteLine"
                                                                            onClick={() => this.subpageRedirect()}
                                                                            key={"humanVertical" + i}
                                                                            data-src={data.acf.image_url}>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        }) : <div></div>
                                                    }
                                                </div>
                                                {this.state.width > 375 &&
                                                    this.props.humanData ? this.props.humanData.map((data, i) => {
                                                        return (
                                                            <div
                                                                id={"Tooltip" + i}
                                                                key={"humantip" + i}
                                                                className="tooltip-content"
                                                                ref={"verticalLine" + i}
                                                                style={{ top: this.state.y, left: this.state.x }}
                                                                // onMouseMove={(e) => this.moveToolTip(e)}
                                                                // onLoad={(e) => this.moveToolTip(e)}
                                                            >
                                                                {data.acf.work_title}
                                                                <br/>
                                                                {data.acf.artist_name}
                                                            </div>
                                                        )

                                                    }) : <div></div>
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <span id="lineCursor" className="lineCursor" style={{left: this.state.lineX + 'px' }}/>
                    </div>
                </div>
                {
                    this.state.fromEventPage
                        ? <PanelFooter history={this.props.history}  />
                        : null
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        categories: state.categoriesData,
        humanData: state.humanData
    }
}

const mapDispatchToProps = {
    mouseIn: baadActions.mousePosIn,
    mouseOut: baadActions.mousePosOut,
     getHumanPostDataBySlug: baadActions.getHumanPostDataBySlug,
     getHumanData: baadActions.getHumanData,
     getPageDataBySlug: baadActions.getPageDataBySlug
}

export default connect(mapStateToProps, mapDispatchToProps)(Humanactivities);
