const baadActions = {
    REQUEST_FOR_CATEGORIES: "REQUEST_FOR_CATEGORIES",
    CATEGORIES_NAME: "CATEGORIES_NAME",
    REQUEST_FOR_CATEGORIES_DATA: "REQUEST_FOR_CATEGORIES_DATA",
    GET_POST_DATA: "GET_POST_DATA",
    GET_POST_DATA_BYSLUG: "GET_POST_DATA_BYSLUG",
    GET_HUMAN_POST_DATA_BYSLUG: "GET_HUMAN_POST_DATA_BYSLUG",
    SET_CATEGORIES_DATA: "SET_CATEGORIES_DATA",
    TOTAL_LINK_COUNTER: "TOTAL_LINK_COUNTER",
    SET_CATEGORIES_RESULT: "SET_CATEGORIES_RESULT",
    GET_PAGE_DATA_BYSLUG: "GET_PAGE_DATA_BYSLUG",
    GET_HUMAN_DATA: "GET_HUMAN_DATA",
    SEND_FRONT_URL: "SEND_FRONT_URL",
    MOUSE_IN: "MOUSE_IN",
    MOUSE_OUT: "MOUSE_OUT",


    requestForGetCategories: () => ({
        type: baadActions.REQUEST_FOR_CATEGORIES,
    }),
    categoriesName: (name) => ({
        type: baadActions.CATEGORIES_NAME,
        name
    }),
    setCetogoriesData: (categories) => ({
        type: baadActions.SET_CATEGORIES_RESULT,
        categories
    }),
    requestForGetCategoriesData: (id, count) => ({
        type: baadActions.REQUEST_FOR_CATEGORIES_DATA,
        id, count
    }),
    getPostData: (postId) => ({
        type: baadActions.GET_POST_DATA, postId
    }),
    getPostDataBySlug: (slug, resolve) => ({
        type: baadActions.GET_POST_DATA_BYSLUG, slug, resolve
    }),
    getHumanPostDataBySlug: (slug, resolve) => ({
        type: baadActions.GET_HUMAN_POST_DATA_BYSLUG, slug, resolve
    }),
    getPageDataBySlug: (slug, resolve) => ({
        type: baadActions.GET_PAGE_DATA_BYSLUG, slug, resolve
    }),
    getHumanData: () => ({
        type: baadActions.GET_HUMAN_DATA
    }),
    sendFrontUrl: (data) => ({
        type: baadActions.SEND_FRONT_URL,
        payload: data
    }),
    mousePosIn: () => ({
        type: baadActions.MOUSE_IN,
        payload: 'in'
    }),
    mousePosOut: () => ({
        type: baadActions.MOUSE_OUT,
        payload: 'out'
    }),
    totalLinkCounter: (count) => ({
        type: baadActions.TOTAL_LINK_COUNTER,
        count
    })
}

export default baadActions;