import React, { Component } from 'react';
import { connect } from 'react-redux';
import baadActions from '../../redux/actions/';
import './panelFooter.scss';
import footerLogo from '../../assets/logo.svg';
import ExhibitionPost from "../exhibitionPost/exhibitionPost";
import galerierudigerschottle from '../../assets/galerierudigerschottle.svg';
import constants from './../../utils/appConstant';

class Footer extends Component {
    constructor(props) {
        super(props);
        var toExhibition = false;
        let exhibitionName = '';
        if (props.history.location.state) {
            if (props.history.location.state.toExhibition) {
                toExhibition = true;
                exhibitionName = props.history.location.state.exhibitionName;
            }
        }
        this.state = {
            pathName: '',
            toExhibition: toExhibition,
            exhibitionName: exhibitionName,
            show: false
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }
    showModal = () => {
        this.setState({ show: true });
    };
    hideModal = () => {
        this.setState({ show: false });
    };
    componentDidMount() {
        const addressUrl = this.props.history.location.pathname;
        const frontUrl = addressUrl.replace('/c/', '');
        this.props.sendFrontUrl(frontUrl !== "/" ? frontUrl : '');
        console.log("history props for footer", frontUrl);
    }
    beckToHome = () => {
        this.props.history.push('/');
        this.props.sendFrontUrl('')
    }
    handleRedirectHome = (e) => {
        const urlString = this.props.frontUrl;
        const catIndex = this.props.history.location.state;
        let homeTransitionTime = 200;
        let beforeCategoryTime = 1300;
        if (catIndex === 0 ) {
            homeTransitionTime = 300;
            beforeCategoryTime = 600;
        } else if (catIndex === 1) {
            homeTransitionTime = 300;
            beforeCategoryTime = 800;
        } else if (catIndex === 2) {
            homeTransitionTime = 500;
            beforeCategoryTime = 900;
        }
        
        if (document.getElementById('showme')) {
            document.getElementById('showme').style.Transition = "all"+ beforeCategoryTime + "ms";
            document.getElementById('artistBarsStyle').style.Transition = "all"+ beforeCategoryTime + "ms";
            document.getElementById('showme').style.marginLeft = 0;
            document.getElementById('artistBarsStyle').style.marginRight = "-200%";
        }
        this.props.sendFrontUrl('');
        if (urlString === '/human-activities' || urlString === '/exhibition') {
            // document.getElementsByClassName('page')[0].classList.add('exit-Right');
            var img = document.getElementById('parentDiv'),
                style = img.currentStyle || window.getComputedStyle(img, false),
                bi = style.backgroundImage.slice(4, -1).replace(/"/g, "");
            setTimeout(() => {
                this.props.history.push({
                    pathname: '/',
                    state: { fromHumanPage: true, backgroundImage: bi }
                })
            }, homeTransitionTime);
        } else {
            setTimeout(() => {
                this.props.history.push({
                    pathname: '/',
                    state: { fromCategoryPage: true }
                })
            }, beforeCategoryTime);
        }
    }
    exhibitionPage = async () => {
        // await new Promise(resolve => {
        //     this.props.getHumanPostDataBySlug(slug, resolve)
        // });
        //document.getElementById('footer_content').style.display = "none";
        // this.props.history.push({
        //     pathname: '/exhibition/1',
        //     // state: { toExhibition:  this.state.toExhibition, exhibitionName: this.state.exhibitionName}
        // });
    }
    render() {
        // console.log("footer props: ", this.props.frontUrl);
        const urlString = this.props.frontUrl;
        // const frontUrl = urlString.replace(" ", " ");
        const frontUrl = urlString.replace(" ", "_");
        const imageFrontUrl = frontUrl.replace("/", "");
        let exhibitionName = "";

        this.props.categoriesData && this.props.categoriesData.forEach(category => {
            const {id, name} = category;
            if (constants.CATEGORY_ID === id) {
                exhibitionName = name
            }
        })

        return (
            <>
            <div id="footer_content" className={urlString === '' ? "footer hover-cursor2" : "footer whatever-class"}>
                <div>
                    <div className="footer__title-wrapper">
                    <div className="d-flex align-items-center">
                        <span className="footer__dot"/>
                        <div className="d-flex flex-column flex-md-row">
                            {window.innerWidth > 375
                            ?
                                <h1
                                    data-js="home-link"
                                    className={`footer__title ${urlString === '' ? 'short' : null}`}
                                    onMouseOver={this.props.mouseIn}
                                    onMouseOut={this.props.mouseOut}
                                    id="footer_logo"
                                >
                                    {exhibitionName}
                                </h1>
                            :
                                <h1
                                    data-js="home-link"
                                    className="footer__title"
                                    id="footer_logo--mobile"
                                >
                                    {exhibitionName}
                                </h1>
                            }
                            {window.innerWidth <= 375
                                ?
                                <h2 className="footer__subtitle">Galerie Rüdiger Schöttle</h2>
                                :
                                <h2 className="footer__subtitle"></h2>
                            }
                        </div>
                    </div>
                    </div>
                </div>
                <div className="d-none d-lg-flex flex-column align-items-end">
                    <a href="https://www.galerie-schoettle.de/" target="_blank">
                      <img src={galerierudigerschottle} alt="" className="galerierudigerschottle-logo"/>
                    </a>
                    <a href="https://str-ing.org/" target="_blank" className="string-logo">
                        <span>powered by str-ing.org</span>
                        <img src={footerLogo} alt="" />
                    </a>
                </div>

            </div>
                <ExhibitionPost show={this.state.show} handleClose={this.hideModal} />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        frontUrl: state.frontUrl,
        categoriesData: state.categoriesData,
    }
}
const mapDispatchToProps = {
    sendFrontUrl: baadActions.sendFrontUrl,
    mouseIn: baadActions.mousePosIn,
    mouseOut: baadActions.mousePosOut,
    requestForGetCategoriesData: baadActions.requestForGetCategoriesData,
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
