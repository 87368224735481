import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import ImageGallery from 'react-image-gallery';
import './humanPost.scss';
import 'react-image-gallery/styles/scss/image-gallery.scss';

import baadActions from '../../redux/actions/';
import HomeLink from '../HomeLink/homeLink';
import PanelFooter from '../panelFooter/panelFooter';
import logo from "../../assets/logo.svg";
import HumanPostForm from "../../components/HumanPostForm/humanPostForm";
import ScrollContainer from 'react-indiana-drag-scroll'
import constants from "../../utils/appConstant";
import {Redirect} from "react-router";

class humanPost extends Component {

    constructor(props) {
        super(props);
        this.showColumn = this.showColumn.bind(this);
        this.openContactForm = this.openContactForm.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.enlargePhoto = this.enlargePhoto.bind(this);
        this.ref = React.createRef();
        this.galleryBig = React.createRef()

        this.state = {
            fromRandomTeaser: false,
            activeIndex: 0,
            activeStep: 0,
            activeTab: 0,
            buyWorkDrawer: false,
            isContactOpen: false,
            isVideo: false,
            template: '',
            isEnlarged: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.history.location.state){
            if(props.history.location.state.fromRandomTeaser){
                return {fromRandomTeaser: true };
            }
        }
        return null;
    }
    componentDidMount() {
        this.props.mouseOut();
    }

    async componentDidUpdate(prevProps) {
        if ( this.props.history.action === "POP" && String(prevProps.humanPostData) === "") {
            let paramsName = this.props.history.location.pathname.slice(2);
            await new Promise(resolve => {
                this.props.getHumanPostDataBySlug(paramsName, resolve)
            });
        }

        var hoverSentence = document.querySelectorAll(".image-src");
        for (let i=0; i<hoverSentence.length; i++){
            if(hoverSentence[i]){
                let dataJs = hoverSentence[i].attributes['data-js'].value;
                hoverSentence[i].addEventListener('mouseover', (e)=>{
                    this.props.mouseIn();
                    let imageUrl = 'https://baadgallery.codingmice.com/' + e.target.attributes['data-image-url'].value;
    
                    if(dataJs === "image-title-src"){
                        let img = document.createElement('img'); 
                        img.src =  imageUrl;
                        img.className = 'hover-image';
                        document.querySelector(".hoverImage-position").appendChild(img);
                    } else {
                        let img = document.createElement('img'); 
                        img.src =  imageUrl;
                        img.className = 'hover-image';
                        document.querySelector(".further-hoverImage-position").appendChild(img);
                    }
                    
                })
                hoverSentence[i].addEventListener('mouseout', ()=>{
                    this.props.mouseOut();
                    if(dataJs === "image-title-src"){
                       document.querySelector(".hover-imgPart").innerHTML = "";
                    }else{
                        document.querySelector(".hover-furtherimgPart").innerHTML = "";
                    }
                })
            }
        }
        var footnote = document.querySelectorAll(".footnote");
        for (let i=0; i<footnote.length; i++){
             if(footnote[i]){
                 footnote[i].addEventListener('mouseover', (e)=>{
                     this.props.mouseIn();
                    let singleFootNote = e.target.attributes['data-note'].value;
                    const classList = document.getElementsByClassName("span_class");
                    const classLength = classList.length;
                    if (classLength === 0) {
                       let span = document.createElement('span');
                     span.className = 'span_class'
                     let posTop = e.pageY;
                     var count =i+1;
                        span.style.cssText = "top: "+posTop+"px; position: relative;";
                        span.innerHTML = "<span class='span_count'>"+count+"</span> "+singleFootNote;
                        document.querySelector(".hover-footNote").appendChild(span);
                    }

                })
                 footnote[i].addEventListener('mouseout', ()=>{
                     this.props.mouseOut();
                     document.querySelector(".footDiv").innerHTML = "";

                })
             }
        }

        if(this.props.humanPostData && this.props.humanPostData.length !== 0){
            let template = this.props.humanPostData.acf.frontend_template;
            const panelTwo = document.getElementById('panels-item--two');
            const contactPanel = document.getElementById('panels-item--three');
            if (template === 'video-3') {
                template = 'video-2'
            }
            if (template === 'video-1') {
                contactPanel.style.left = '0';
                contactPanel.style.maxWidth = '100%';
            }
            if (template === 'video-2' || template === 'gallery-big') {
                panelTwo.style.left = '0';
                panelTwo.style.margin = 'unset';
                contactPanel.classList.add('video-2--three')
            }
            if (template === 'default') {
                panelTwo.style.zIndex = '1';
            }

            const headingForRedirect = document.querySelector('h2.headingForRedirect')
            headingForRedirect.textContent = this.props.humanPostData.acf.work_title;

            const scrollContainer = document.querySelector('.scroll-container');
            if (scrollContainer && this.props.humanPostData.acf.image_position) {
                const [positionX, positionY] = this.props.humanPostData.acf.image_position.split(',');
                scrollContainer.scrollLeft = parseInt(positionX);
                scrollContainer.scrollTop = parseInt(positionY);
            }
        }
    }
    clickHomeLink = async() => {
        document.getElementsByClassName('page')[0].classList.remove('exit-Down');
        document.getElementsByClassName('page')[0].classList.add('exit-Right');
        
        await new Promise(resolve => {
            this.props.getPageDataBySlug('random-teaser', resolve);
        });
        let currentPath = this.props.history.location.pathname;
        this.props.history.push({
            pathname: constants.INTRO_URL === 'intro' ? "/gallery" : '/human-activities',
            state: {beforePath: currentPath}
        });
    }

    handleRedirectHome = (e) => {
        // document.getElementsByClassName('page')[0].classList.remove('exit-Down');
        // document.getElementsByClassName('page')[0].classList.add('exit-Right');
        this.props.history.push({
            pathname: constants.INTRO_URL === 'intro' ? "/gallery" : '/human-activities',
            state: {fromEventPage: true}
        });
    }

    handleRedirectHuman = () => {
        document.getElementsByClassName('headerDiv')[0].style.padding = "0px 10px 58px";
        if (this.props.history.location.state.toExhibition) {
            this.props.history.push({
                pathname: '/exhibition',
                state: {
                    fromEventPage: true, 
                    toExhibition:  this.props.history.location.state.toExhibition, 
                    exhibitionName: this.props.history.location.state.exhibitionName
                }
            })
        } else {
            this.props.history.push({
                pathname: constants.INTRO_URL === 'intro' ? "/gallery" : '/human-activities',
                state: {
                    fromEventPage: true, 
                    toExhibition:  false, 
                    exhibitionName: '',
                }
            })
        }
    }

    handleRedirectArtist = async(slug) => {
        document.getElementsByClassName('page')[0].classList.remove('exit-Down');
        document.getElementsByClassName('page')[0].classList.add('exit-Up');
        await new Promise(resolve => {
            this.props.getPostDataBySlug(slug, resolve);
        });
        this.props.history.push({
            pathname: '/'+slug,
            // state: {fromEventPage: true}
        });
    }

    showColumn = (data, e) => {
        const panels = document.getElementsByClassName("panels-item");

        if(data.currentTarget) {
            [].forEach.call(panels, function(el) {
                el.classList.remove( "action-panel" );
            });

            for(let i=0; i < panels.length; i++) {
                if (panels[i].id === data.currentTarget.id) {
                    panels[i].classList.add("action-panel");
                    panels[1].setAttribute("style", "z-index: 2");
                }
            }
        }
    }

    deleteImage(item,i){
        document.getElementById('image_div'+i).classList.add("hide-item");
        const showDom = this.refs.closeImage;
        showDom.classList.add("show-item");
        this.handler = setTimeout(function(){
            showDom.classList.remove('show-item');
        }, 50)
    }

    changeSlider(i) {
        this.setState({
            activeIndex: i
        });
    }

    openContactForm() {
        this.setState({
            isContactOpen: !this.state.isContactOpen
        })
    }

    onFormSubmit = (newActiveStep) => {
        this.setState({
            activeStep: newActiveStep
        });
    };

    changeTitle(e) {
        const headingForRedirect = document.querySelector('h2.headingForRedirect')
        const targetID = e.currentTarget.id;

        if (!this.state.isContactOpen && window.innerWidth > 375) {
            if (targetID === 'first-column') {
                headingForRedirect.textContent = this.props.humanPostData.acf.artist_name
            }
            if (targetID === 'panels-item--one') {
                headingForRedirect.textContent = this.props.humanPostData.acf.column_1_header
            }
            if (targetID === 'panels-item--two') {
                headingForRedirect.textContent = this.props.humanPostData.acf.column_2_header
            }
            if (targetID === 'panels-item--three') {
                headingForRedirect.textContent = 'INFO'
            }
        }
    }

    handleTabChange(e, i) {
        this.setState({
            activeTab: i
        })
    }

    toggleScrolling = (isEnable) => {
        if (isEnable) {
            window.addEventListener('mousemove', this.onMouseMove);
            window.addEventListener('mouseup', this.onMouseUp);
        } else {
            window.removeEventListener('mousemove', this.onMouseMove);
        }
    };

    enlargePhoto() {
        const firstSecondColumn = document.querySelector('.first-second-column');
        const firstColumn = document.getElementById('first-column');
        const galleryBigImg = document.querySelector('.gallery-big__img');
        this.setState({
            isEnlarged: !this.state.isEnlarged
        })

        if (window.innerWidth > 375) {
            if (!this.state.isEnlarged) {
                firstSecondColumn.style.transform = "translateX(1000px)"
                setTimeout(() => {
                    firstSecondColumn.style.display = "none"
                    firstColumn.style.maxWidth = "100%"
                    firstColumn.style.flex = "1"
                }, 1000);
                setTimeout(() => {
                    galleryBigImg.style.position = "unset";
                }, 1500);
            } else {
                firstSecondColumn.style.display = "block"
                firstColumn.style.maxWidth = "50%"
                galleryBigImg.style.position = "absolute";
                setTimeout(() => {
                    firstColumn.style.flex = "0 0 50%"
                    firstSecondColumn.style.transform = "translateX(0)"
                }, 500);
            }
        }
    }

    render() {
        if(!this.props.humanPostData) {
            return <Redirect to='/' />
        }
        if(this.props.humanPostData.length === 0){
            return null
        }

        let template = this.props.humanPostData.acf.frontend_template;
        const tabs = template === 'video-1' ? ['Media', 'Infos']  : ['Media', 'Texts', 'Infos'];

        if (this.props.humanPostData.acf['images-killer']) {
            this.images = this.props.humanPostData.acf['images-killer'].map((data, i) => {
                return {
                    original: data['images-killer-image'].url,
                    originalTitle: data['images-killer-image'].title,
                    description: data['images-killer-image'].title,
                    thumbnailTitle: data['images-killer-image'].title,
                    originalAlt: data['images-killer-image'].alt,
                    thumbnailAlt: data['images-killer-image'].alt,
                }
            })
        }

        if (template === 'video-3') {
            template = 'video-2'
        }

        return (
            <div className={"whatever-class humanPost-Page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>
                <HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}/>
                <div className="headerDiv headerDiv-artist">
                    {template === 'text'
                    ?
                    <Row className="main-border">
                        <div className="text-template-wrapper">
                            <p className="text-template__text">
                                { ReactHtmlParser(this.props.humanPostData.acf.column_1) }
                            </p>
                            <p className="text-template__description">
                                { ReactHtmlParser(this.props.humanPostData.acf.column_2) }
                            </p>
                        </div>
                    </Row>
                    :
                    <Row className={this.state.isContactOpen ? "main-border py-0" : "main-border"}>
                        {template === 'video-1' || template === 'video-2' ?
                        <Col
                            onMouseOver={(e) => this.changeTitle(e)}
                            id="first-column"
                            md={template === 'video-1' ? 8 : template === 'video-2' ? 6 : 4} sm={12} xs={12}
                            className={this.state.activeTab === 0 ? 'panels-item--active' : 'first-column'}
                            style={{position: 'relative'}}>
                            <div className="video-container">
                                <div className={template === 'video-2' ? 'video-iframe' : 'video-iframe video2-iframe'}>
                                    <iframe
                                    src={this.props.humanPostData.acf.video_url}
                                    frameBorder="0" allowFullScreen="allowfullscreen"/>
                                </div>
                                {/*{ ReactHtmlParser(this.props.humanPostData.acf.column_1) }*/}
                            </div>
                        </Col>
                        :
                        <Col
                            onMouseOver={(e) => this.changeTitle(e)}
                            id="first-column"
                            md={template === 'video-1' ? 8 : template === 'video-2' || template === 'gallery-big' ? 6 : 4} sm={12} xs={12}
                            className={this.state.activeTab === 0 ? 'panels-item--active' : 'first-column'}
                            style={{position: 'relative'}}>
                            {template !== 'gallery-big' ?
                                <>
                                    {this.images &&
                                    <>
                                    <div className="image-gallery-container">
                                        <div className="image-gallery-wrapper">
                                            <ImageGallery
                                                items={this.images}
                                                showThumbnails={false}
                                                showPlayButton={false}
                                                onSlide={(index) => this.changeSlider(index)}
                                            />
                                        </div>
                                    </div>
                                    {this.images[this.state.activeIndex].description &&
                                    <p className="gallery-description">
                                        {window.screen.width <= 375
                                            ?
                                            this.images[this.state.activeIndex].description.split(',').join("\n")
                                            :
                                            this.images[this.state.activeIndex].description
                                        }
                                    </p>}
                                    </>}
                                </>
                                :
                                <div className={this.state.isEnlarged ? 'gallery-big gallery-big--enlarged' : 'gallery-big'}>
                                    <ScrollContainer className="scroll-container">
                                    {this.props.humanPostData.acf.panorama_image &&
                                        <img className="gallery-big__img" src={this.props.humanPostData.acf.panorama_image}
                                             alt=""/>
                                    }
                                    <div className={this.state.isEnlarged ? 'gallery-big__btn gallery-big__btn--enlarged' : 'gallery-big__btn'} onClick={this.enlargePhoto}/>
                                    </ScrollContainer>
                                    {this.props.humanPostData.acf.column_1 &&
                                    <p className="gallery-description">
                                        {window.screen.width <= 375 &&
                                        <>
                                            { ReactHtmlParser(this.props.humanPostData.acf.column_1.split(',').join("\n")) }
                                        </>
                                        }
                                    </p>}
                                </div>
                            }
                        </Col>}
                        <Col id={this.state.activeTab === 0 ? '' : !this.state.isContactOpen ? 'third-fourth-column' : 'contact-column'}
                             md={template === 'video-1' ? 4 : template === 'video-2' || template === 'gallery-big' ? 6 : 8}
                             sm={12} xs={12}
                             className="first-second-column"
                             style={{ }}>

                            <Row>
                                {template !== 'video-1' &&
                                <>
                                {template !== 'video-2' && template !== 'gallery-big' ?
                                <Col
                                    onMouseOver={(e) => this.changeTitle(e)}
                                    id="panels-item--one"
                                    className={this.state.activeTab === 1 ? 'panels-item panels-item--one panels-item--active' : 'panels-item panels-item--one'}
                                    lg={4} md={4} sm={6} xs={12}
                                    onClick={this.showColumn}>
                                    { ReactHtmlParser(this.props.humanPostData.acf.column_1) }
                                </Col> : ''}
                                <Col
                                    onMouseOver={(e) => this.changeTitle(e)}
                                    id="panels-item--two"
                                    className={this.state.activeTab === 1 ? 'panels-item panels-item--two panels-item--active' : 'panels-item panels-item--two'}
                                    lg={4} md={6} sm={6} xs={12}
                                    onClick={this.showColumn}>

                                    {this.props.humanPostData.acf.frontend_template === 'video-3'
                                        ?
                                        <p className="video-3__text">
                                            {
                                                typeof(this.props.humanPostData.acf.column_2) !== 'undefined' ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.props.humanPostData.acf.column_2.replace(/<\/?[^>]+(>|$)/g, "")}}></div>
                                                    :
                                                    ''
                                            }
                                        </p>
                                        :
                                        <h2 className="h2HeadingStyle">
                                            {
                                                typeof(this.props.humanPostData.acf.column_2) !== 'undefined' ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.props.humanPostData.acf.column_2.replace(/<\/?[^>]+(>|$)/g, "")}}></div>
                                                    :
                                                    ''
                                            }
                                        </h2>
                                    }
                                </Col>
                                </>}

                                <Col id="panels-item--three"
                                     onMouseOver={(e) => this.changeTitle(e)}
                                     className={this.state.activeTab === tabs.length - 1 ? 'panels-item panels-item--three panels-item--active' : 'panels-item panels-item--three'}
                                     lg={5} md={5} sm={6} xs={12}
                                     onClick={template !== 'video-1' ? this.showColumn : undefined}>

                                    {template !== 'default'
                                        ?
                                        <>
                                            <HumanPostForm
                                                isContactOpen={this.state.isContactOpen}
                                                openContactForm={this.openContactForm}
                                                humanPostData={this.props.humanPostData}
                                                handlePostSubmit={this.onFormSubmit}
                                                activeStep={this.state.activeStep}/>

                                            {!this.state.isContactOpen &&
                                            <>
                                                <div className="column3-header">
                                                    {this.props.humanPostData.acf.work_title} <br/>
                                                    {this.props.humanPostData.acf.artist_name && this.props.humanPostData.acf.artist_name}
                                                </div>
                                                <div className="column3-description">
                                                    {this.props.humanPostData.acf.buy_column_description &&
                                                    <p className="column3-description__item">
                                                        {this.props.humanPostData.acf.buy_column_description}
                                                    </p>}
                                                    {this.props.humanPostData.acf.technique &&
                                                    <p className="column3-description__item">
                                                        Technique: {this.props.humanPostData.acf.technique}
                                                    </p>
                                                    }
                                                    {this.props.humanPostData.acf.file_type &&
                                                    <p className="column3-description__item">
                                                        File Type: {this.props.humanPostData.acf.file_type}
                                                    </p>
                                                    }
                                                    {this.props.humanPostData.acf.edition_number &&
                                                    <p className="column3-description__item">
                                                        EDITION NUMBER: {this.props.humanPostData.acf.edition_number}
                                                    </p>
                                                    }
                                                    {this.props.humanPostData.acf.collector_receives &&
                                                    <p className="column3-description__item">
                                                        Collector
                                                        ReceiveS: {this.props.humanPostData.acf.collector_receives}
                                                    </p>
                                                    }
                                                </div>
                                                <div className="mb20">
                                                    {
                                                        this.props.humanPostData.acf.participating_artists &&
                                                        <h2>Participating artists</h2>
                                                    }

                                                    <div className="artistsList">
                                                        {
                                                            this.props.humanPostData.acf.participating_artists &&
                                                            this.props.humanPostData.acf.participating_artists.map((data, i) => {
                                                                return (
                                                                    data.participating_artist !== null ?
                                                                        <p key={"artist" + i} className="aTagStyle"
                                                                           onClick={(item) => this.handleRedirectArtist(data.participating_artist.post_name)}>
                                                                            <a href={data.participating_artist.postname}><span>{data.participating_artist.post_title}</span></a>
                                                                        </p>
                                                                        :
                                                                        ''
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.props.humanPostData.acf.link_for_website !== "" &&
                                                    <a className="aTagStyle"
                                                       href={this.props.humanPostData.acf.link_for_website}>[link for
                                                        website]</a>
                                                }

                                                {this.props.humanPostData.acf.price &&
                                                <>
                                                    <p className="panels-item__nft-price">
                                                        Price: <span className="text-lowercase">{this.props.humanPostData.acf.price}</span>
                                                    </p>

                                                    <a onClick={this.openContactForm} className="btn--link">
                                                        Purchase ≥
                                                    </a>
                                                </>}
                                            </>}
                                        </>
                                        :
                                        <>
                                            <div className="column3-header">
                                                {this.props.humanPostData.acf.work_title}
                                            </div>
                                            <div className="column3-default-description">
                                                { ReactHtmlParser(this.props.humanPostData.acf.column_3) }
                                            </div>
                                            {
                                                this.props.humanPostData.acf.link_for_website !== "" &&
                                                <a className="aTagStyle" href={this.props.humanPostData.acf.link_for_website}>[link for website]</a>
                                            }
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Col>

                        {!this.state.isContactOpen &&
                        <div className={template === 'video-1' ? "tabs justify-content-around" : "tabs"}>
                            {tabs.map((data, i) => {
                                    return (
                                        <span
                                            key={i}
                                            className={i === this.state.activeTab ? 'tab tab--active' : 'tab'}
                                            onClick={(e) => this.handleTabChange(e, i)}>
                                                { data }
                                            </span>
                                    )
                                }
                            )}
                        </div>
                        }
                    </Row>}
                </div>

                {!this.state.isEnlarged && !this.state.isContactOpen &&
                    <img className="humanPost-Page__logo" src={logo} alt=""/>
                }

                <PanelFooter
                    history={this.props.history}
                    urlPath={this.props.humanPostData.title.rendered}
                    isHumanPostPage={true}
                    isContactOpen={this.state.isContactOpen}
                    handler={this.openContactForm}
                    onClickLogo={(e) => this.handleRedirectHome(e)}
                    activeFormStep={this.state.activeStep}
                    onClikHeaderCategory={() => this.handleRedirectHuman()}>
                </PanelFooter>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        humanPostData: state.humanPostData,
    }
}

const mapDispatchToProps = {
    getPostDataBySlug: baadActions.getPostDataBySlug,
    getHumanPostDataBySlug: baadActions.getHumanPostDataBySlug,
    getPageDataBySlug: baadActions.getPageDataBySlug,
    mouseIn: baadActions.mousePosIn,
    mouseOut: baadActions.mousePosOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(humanPost);
