import React, { Component } from 'react';
import { connect } from "react-redux";

import baadActions from '../../redux/actions';
import HomeLink from '../HomeLink/homeLink';
import PanelFooter from '../panelFooter/panelFooter';
import './artistCategory.scss';
class ArtistCategory extends Component {
    constructor(props) {
        super(props);

        var fromArtist = false;
        var toHomePage = false;

        if (props.history.location.state) {
            if (props.history.action !== "POP" && props.history.location.state.fromArtist) {
                fromArtist = true;
            }
            if (props.history.location.state.toHomePage) {
                toHomePage = true;
            }
        }
        this.state = {
            x: 0,
            y: 0,
            fromArtist: fromArtist,
            toHomePage: toHomePage            
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     if(props.history.location.state){
    //         if(props.history.location.state.fromArtist){
    //             return {fromArtist: true };
    //         }
    //     }
    //     return null;
    // }

    componentDidMount() {
        if(this.state.fromArtist) {
            document.getElementById('footer_content').classList.add('footer-d-n');
            setTimeout(() => {
                document.getElementById('footer_content').classList.remove('footer-d-n');
                document.getElementsByClassName('panel-footer')[0].style.display="none";
            }, 1500)
        }


        if(this.state.toHomePage) {
            setTimeout(() => {
                const catIndex = this.props.history.location.state;
                let beforeCategoryTime = 1300;
                if (catIndex === 0 ) {
                    beforeCategoryTime = 600;
                } else if (catIndex === 1) {
                    beforeCategoryTime = 800;
                } else if (catIndex === 2) {
                    beforeCategoryTime = 900;
                }
                if (document.getElementById('showme')) {
                    document.getElementById('showme').style.Transition = "all"+ beforeCategoryTime + "ms";
                    document.getElementById('artistBarsStyle').style.Transition = "all"+ beforeCategoryTime + "ms";
                    document.getElementById('showme').style.marginLeft = 0;
                    document.getElementById('artistBarsStyle').style.marginRight = "-200%";
                }
                setTimeout(() => {
                    this.props.history.push({
                        pathname: '/',
                        state: { fromCategoryPage: true }
                    })
                }, beforeCategoryTime);
            }, 1000);
            
        }
    }

    handleRedirect = async(data) => {
        this.props.mouseOut();
        document.getElementsByClassName('page')[0].classList.remove('exit-Right');
        document.getElementsByClassName('page')[0].classList.add('exit-Up');
        if (this.props || this.props.categoriesData === undefined) {
            await new Promise(resolve => {
            this.props.getPostDataBySlug(data.post_name, resolve)
            });
            
            document.getElementsByClassName('headerDiv')[0].style.padding = "80px 12px 0px";
            document.getElementById('footer_content').classList.add('footer-d-n');
            this.props.history.push('/' + data.post_name);
        }
    }

    clickHomeLink = async() => {
        await new Promise(resolve => {
            this.props.getPageDataBySlug('random-teaser', resolve)
        });

        let currentPath = this.props.history.location.pathname;
        this.props.history.push({
            pathname: '/random-teaser',
            state: {beforePath: currentPath}
        });
    }

    componentDidUpdate(prevProps) {
        if ( this.props.history.action === "POP" && prevProps.categoriesData !== this.props.categoriesData) {
            let paramsName = this.props.match.params.id;

            this.props.categoriesData.map((data, index) => {
                if (data.slug === paramsName) {
                    this.props.requestForGetCategoriesData(data.id, data.count);
                }
                return true;
            })
        }
    }

    /*--------- vertical line create  ---------*/
    handleRows = () => {
        if (this.props.categoriesDataContent && this.props.categoriesDataContent.length > 0) {
            // for (var i = 0; i < this.props.categoriesDataContent.length; i++) {
            //     this.props.categoriesDataContent[i].artistName = this.props.categoriesDataContent[i].slug.split('-').join(' ');
            // }

            //sort artists alphabetically by artistName:
            // this.props.categoriesDataContent.sort(function(a, b) {
            //     return a.artistName.localeCompare(b.artistName);
            // });

            return this.props.categoriesDataContent && this.props.categoriesDataContent.map((data, index) => {
                return (
                    <div key={"vline" + index} className="vertical-line tip" onClick={() => this.handleRedirect(data)}
                        style={{ borderWidth: this.props.lineWidth }}
                        onMouseMove={(e) => this.moveToolTip(e)}
                        onMouseOver={() => this.props.mouseIn()}
                        onMouseLeave={() => this.props.mouseOut()}
                        >
                            <span style={{ top: this.state.y, left: this.state.x }}> {data.post_title} </span>
                    </div>
                );
            })
        }
    }

    moveToolTip = e => {
        let offsetRight = window.innerWidth - e.clientX;
        if (offsetRight < 220) {
            let elementWidth = e.target.firstChild.scrollWidth;
            this.setState({
                x: -elementWidth - 25,
                y: e.clientY - 20
            });
        } else {
            this.setState({
                x: 25,
                y: e.clientY - 20
            });
        }
    };
    /* end */

    /* before category page*/
    beforeCategoryPage = () => {
        if (this.props.categoriesData && this.props.categoriesData.length > 0) {
            let currentId = this.props.currentId;
            let currentIndex = 0;
            this.props.categoriesData.map((category, i) => {
                if (category.id === currentId) {
                    currentIndex = i;
                    
                }
                return true;
            });
            if(currentIndex !== 0){

                return this.props.categoriesData.slice(0, currentIndex).map((data, index) => {
                    let categoryNum = data.count;
                    let mLeft = "-" + currentIndex*100 + "%";
                    let verticalLines = [];
                    let lineWidth = "10px";

                    if (categoryNum > 45) {
                        lineWidth = "1px";
                    }
                    else if (categoryNum > 40) {
                        lineWidth = "2px";
                    }
                    else if (categoryNum > 30) {
                        lineWidth = "3px";
                    }
                    else if (categoryNum > 25) {
                        lineWidth = "4px";
                    }
                    else if (categoryNum > 20) {
                        lineWidth = "5px";
                    }
                    else if (categoryNum > 15) {
                        lineWidth = "6px";
                    }
                    else if (categoryNum > 10) {
                        lineWidth = "7px";
                    }
                    else if (categoryNum > 6) {
                        lineWidth = "8px";
                    }
                    else if (categoryNum > 3) {
                        lineWidth = "9px";
                    }

                    for(let i=0; i<categoryNum; i++){
                        verticalLines.push(
                            <div key={"verticleline" + i} className="vertical-line tip" style={{ borderWidth:lineWidth }}>
                            </div>
                        )
                    }

                    if (index === 0){
                        return (
                            <div className="lines" id="showme"  style={{ borderWidth:lineWidth, marginLeft: mLeft }} key={"bvline" + index} ref="firstBeforePage">
                                <div className="artistBarsStyle">
                                    {verticalLines}
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className="lines" style={{ borderWidth:lineWidth }} key={"bvline" + index}>
                                <div className="artistBarsStyle">
                                    {verticalLines}
                                </div>
                            </div>
                        )
                    }
                });
            }
        }
    }

    render() {
        return (
            <div className={"artistCategory-page page exit-Right " + (this.state.fromArtist ? 'enter-Down' : 'enter-Right')}>

                <HomeLink history={this.props.history}
                    onOver={() => this.props.mouseIn()}
                    onLeave={() => this.props.mouseOut()}
                    clickHomeLink={()=>this.clickHomeLink()}
                    isCategoryPage="true" >
                </HomeLink>
                <div id="artistCategoryHeaderDiv" className="headerDiv">
                    <div className="artistContentDiv animation-content">
                        {this.beforeCategoryPage()}
                        <div id="artistBarsStyle" className="artistBarsStyle" style={{ borderWidth: this.props.lineWidth }} >
                            {this.handleRows()}
                        </div>
                    </div>
                </div>
                {
                this.state.fromArtist
                    ? <PanelFooter history={this.props.history} />
                    : null
                }
            </div>
        );
    }
}
function mapStateToProps(state) {
    if (state.categoriesData.length !== 0) {
        let categoriesData = state.categoriesData;

        for (let el of categoriesData) {
            if (el.slug === window.location.pathname.split("/").pop()) {
                state.currentId = el.id;
                break;
            }
        }
    }
    if (state.categoriesDataContent) {
        let categoriesLength = state.categoriesDataContent.length;

        if (categoriesLength > 45) {
            state.lineWidth = "1px";
        }
        else if (categoriesLength > 40) {
            state.lineWidth = "2px";
        }
        else if (categoriesLength > 30) {
            state.lineWidth = "3px";
        }
        else if (categoriesLength > 25) {
            state.lineWidth = "4px";
        }
        else if (categoriesLength > 20) {
            state.lineWidth = "5px";
        }
        else if (categoriesLength > 15) {
            state.lineWidth = "6px";
        }
        else if (categoriesLength > 10) {
            state.lineWidth = "7px";
        }
        else if (categoriesLength > 6) {
            state.lineWidth = "8px";
        }
        else if (categoriesLength > 3) {
            state.lineWidth = "9px";
        }
        else {
            state.lineWidth = "10px";
        }
    }

    return {
        categoriesData: state.categoriesData,
        categoriesDataContent: state.categoriesDataContent,
        categoryName: state.categoryName,
        lineWidth: state.lineWidth,
        currentId: state.currentId,
    }
}
const mapDispatchToProps = {
    requestForGetCategoriesData: baadActions.requestForGetCategoriesData,
    getPostData: baadActions.getPostData,
    categoriesName: baadActions.categoriesName,
    getPageDataBySlug: baadActions.getPageDataBySlug,
    mouseIn: baadActions.mousePosIn,
    mouseOut: baadActions.mousePosOut,
    getPostDataBySlug: baadActions.getPostDataBySlug,
}
export default connect(mapStateToProps, mapDispatchToProps)(ArtistCategory)


