import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import baadActions from '../../redux/actions';
import './artist.scss';
import HomeLink from '../HomeLink/homeLink';
import PanelFooter from '../panelFooter/panelFooter';
import ReactHtmlParser from 'react-html-parser';

class artist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postData: '',
            addedImagesNumber: 0,
            deletedImagesNumber: 0,
            displayPage: false,
            fromRandomTeaser: false,
            addedTestImagesNumber: 0,
            deletedTestImagesNumber: 0,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.history.location.state) {
            if (props.history.location.state.fromRandomTeaser) {
                return { fromRandomTeaser: true };
            }
        }
        return null;
    }
    componentDidMount() {
        document.getElementById('footer_content').style.display = "none";
        document.getElementsByClassName('footer')[0].classList.add('footer-d-n');
    }

    async componentDidUpdate(prevProps) {
        if (this.props.history.action === "POP" && String(prevProps.postData) === '') {
            let paramsName = this.props.match.params.id;
            await new Promise(resolve => {
                this.props.getPostDataBySlug(paramsName, resolve)
            });
        }
    }

    handleRedirect = (des) => {
        this.props.requestForGetCategoriesData(this.props.categoryId, this.props.categoryCount);
        document.getElementsByClassName('headerDiv')[0].style.padding = "0px 10px 58px";
        document.getElementsByClassName('panel-footer')[0].style.display = "none";
        if (des === "sectionPage") {
            this.props.history.push({
                pathname: '/c/' + this.props.categoryName,
                state: { fromArtist: true }
            });
        }
        else if (des === "homePage") {
            this.props.history.push({
                pathname: '/c/' + this.props.categoryName,
                state: { fromArtist: true, toHomePage: true }
            });
        }


    }

    clickHomeLink = async () => {
        document.getElementsByClassName('page')[0].classList.remove('exit-Left');
        document.getElementsByClassName('page')[0].classList.add('exit-Right');

        await new Promise(resolve => {
            this.props.getPageDataBySlug('random-teaser', resolve)
        });

        let currentPath = this.props.history.location.pathname;
        this.props.history.push({
            pathname: '/random-teaser',
            state: { beforePath: currentPath }
        });
    }

    /*  column animation */
    showColumn = (item) => {
        if (item.currentTarget.classList.contains('action-panel')) {
            document.querySelectorAll(".panels-item")[0].classList.add("action-panel");
            document.querySelectorAll(".panels-item")[1].classList.add("action-panel");
            item.currentTarget.classList.remove("action-panel");
        }
        else {
            document.querySelectorAll(".panels-item")[0].classList.remove("action-panel");
            document.querySelectorAll(".panels-item")[1].classList.remove("action-panel");
            item.currentTarget.classList.add("action-panel");
        }
    }

    showSecondColumn = () => {
        document.querySelector(".panels-item--two").classList.add("action-panel");
        document.querySelector(".panels-item--three").classList.remove("action-panel");
    }
    /* end */


    /* ------ image add, remove, italic title, prompt image ----- */
    deleteImage = e => {

        let elems = document.getElementsByClassName('panel-image');
        [].forEach.call(elems, function (el) {
            el.classList.remove("prompted-image");
        });

        let imageLength = this.props.postData.acf.media.length;
        console.log ("imageLength: " + imageLength);
        // Find and substract false images
        let falseImages = 0;
        for (let i = 0; i < imageLength; i++) {
            if (this.props.postData.acf.media[i].media_image === false) {
                falseImages = falseImages + 1;
            }
        }
        imageLength = imageLength - falseImages;

        let deletedImagesNumber = this.state.deletedImagesNumber;

        let panelsItemOne = document.getElementById("panels-item--one");
        let panelsItemTwo = document.getElementById("panels-item--two");
        let plusImage = document.getElementById("plus-image");

        if (imageLength !== deletedImagesNumber) {
            let targetId = e.target.id;

            this.refs[targetId].classList.remove("font-italic");
            // e.target.classList.add("hide-item");
            document.getElementById("image_div" + e.target.id).classList.add("hide-item");
            const showDom = this.refs.closeImage;
            showDom.classList.add("show-item");
            this.setState({
                deletedImagesNumber: deletedImagesNumber + 1
            });
            this.handler = setTimeout(function () {
                showDom.classList.remove('show-item');
            }, 50);
        }
        // when all images are killed, MC (middle column) goes on top:
        if (this.state.deletedImagesNumber === imageLength - 1) {
            panelsItemTwo.classList.add("action-panel");
            panelsItemOne.classList.add("empty");
            panelsItemOne.addEventListener('mousemove', function() {
                if (panelsItemOne.classList.contains("empty")) {
                    plusImage.classList.add("show-for-a-while");
                }
            })
        }
    }

    deleteTestImage = e => {

        let elems = document.getElementsByClassName('panel-image');
        [].forEach.call(elems, function (el) {
            el.classList.remove("prompted-image");
        });

        let imageLength = this.props.postData.acf['images-killer'].length;
        let deletedImagesNumber = this.state.deletedTestImagesNumber;
        // let addedImagesNumber = this.state.addedTestImagesNumber;

        if (imageLength !== deletedImagesNumber) {
            // let targetId = e.target.id;

            //this.refs[targetId].classList.remove("font-italic");

            e.target.classList.add("hide-item");
            const showDom = this.refs.closeImage;
            showDom.classList.add("show-item");
            this.setState({
                deletedTestImagesNumber: deletedImagesNumber + 1
            });
            this.handler = setTimeout(function () {
                showDom.classList.remove('show-item');
            }, 50);
        }
    }


    addImage = () => {
        let elems = document.getElementsByClassName('panel-image');
        [].forEach.call(elems, function (el) {
            el.classList.remove("prompted-image");
        });

        if (this.props.postData.acf.media) {

            let imageLength = this.props.postData.acf.media.length;
            // Find and substract false images:
            // let falseImages = 0;
            // for (let i = 0; i < imageLength; i++) {
            //     if (this.props.postData.acf.media[i].media_image === false) {
            //         falseImages = falseImages + 1;
            //     }
            // }
            // let imageLength = imageLength - falseImages;

            let deletedImagesNumber = this.state.deletedImagesNumber;
            let addedImagesNumber = this.state.addedImagesNumber;

            let panelsItemOne = document.getElementById("panels-item--one");
            let panelsItemTwo = document.getElementById("panels-item--two");

            if (imageLength === deletedImagesNumber) {
                let targetId = this.props.postData.acf.media[addedImagesNumber].media_image.id;
                document.getElementById("image_div" + targetId).classList.remove("hide-item");
                panelsItemOne.classList.remove("empty");

                const showDom = this.refs.plusImage;
                showDom.classList.add("show-item");
                this.handler = setTimeout(function () {
                    showDom.classList.remove('show-item');
                }, 50);

                this.setState({
                    addedImagesNumber: addedImagesNumber + 1
                });

                if (addedImagesNumber === imageLength - 1) {
                    this.setState({
                        deletedImagesNumber: 0,
                        addedImagesNumber: 0
                    });

                }

                if (addedImagesNumber === 0) {
                    panelsItemTwo.classList.remove("action-panel");
                    document.getElementById("plus-image").classList.remove("show-for-a-while");
                }
            }
        }

        if (typeof this.props.postData.acf['images-killer'] !== 'undefined') {

            let imageLength = this.props.postData.acf['images-killer'].length;
            let deletedImagesNumber = this.state.deletedTestImagesNumber;
            let addedImagesNumber = this.state.addedTestImagesNumber;

            if (imageLength === deletedImagesNumber) {
                let targetId = this.props.postData.acf['images-killer'][addedImagesNumber]['images-killer-image'].id;
                document.getElementById(targetId).classList.remove("hide-item");

                const showDom = this.refs.plusImage;
                showDom.classList.add("show-item");
                this.handler = setTimeout(function () {
                    showDom.classList.remove('show-item');
                }, 50);

                this.setState({
                    addedTestImagesNumber: addedImagesNumber + 1
                });

                if (addedImagesNumber === imageLength - 1) {
                    this.setState({
                        deletedTestImagesNumber: 0,
                        addedTestImagesNumber: 0
                    });
                }
            }
        }

    }

    // resizeImage = e => {
    //     let boxHeight = document.getElementById("panels-item--one").offsetHeight;
    //     e.target.height = boxHeight;
    // }

    addFontItalic = e => {
        let targetId = e.target.id;
        this.refs[targetId].classList.add("font-italic");
    }
    removeFontItalic = e => {
        let targetId = e.target.id;
        this.refs[targetId].classList.remove("font-italic");
    }

    promptImage = async (id) => {
        let elems = document.getElementsByClassName('panel-image');
        [].forEach.call(elems, function (el) {
            el.classList.remove("prompted-image");
            el.classList.remove("hide-item");
        });
        await this.setState({
            deletedImagesNumber: 0,
            addedImagesNumber: 0
        });
        document.getElementById(id).classList.add("prompted-image");
    }

    /*-- end --*/

    render() {
        const lineWidth = this.props.lineWidth;
        const style = {
            borderLeft: lineWidth + " solid",
            padding: "0"
        };
        const borderRight = {
            borderRight: lineWidth + " solid",
            padding: "0"
        };
        const borderStyle = {
            border: lineWidth + " solid black"
        }
        // const urlString = this.props.frontUrl.replace("/","");


        if (this.props.postData.length === 0) {
            return null;
        }
        return (
            <div className={"whatever-class artist-page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>
                <HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}></HomeLink>

                <div id="headerDiv-artist" className="headerDiv headerDiv-artist">
                    <Row style={borderStyle}>
                        <Col lg={7} md={7} sm={12} xs={12} id="panels-item--one" className="image-panel panels-item--one" onMouseUp={this.addImage} onClick={this.columnTwoDown} ref="node">
                            <div className="content-column1">
                                { ReactHtmlParser(this.props.postData.acf.column_1) }
                            </div>
                            {
                                this.props.postData.acf &&
                                <div>
                                    {

                                        this.props.postData.acf.media &&
                                        this.props.postData.acf.media.map((data, i) => {
                                            let imageWidth = data.media_image.width;
                                            let imageHeight = data.media_image.height;
                                            if (imageWidth >= imageHeight) {
                                                return (
                                                    <div id={"image_div"+data.media_image.id} key={i}>
                                                        <div className="image_title_wrap" style={{zIndex: 10 + i}}>

                                                        </div>
                                                        <img key={i} alt="" className="panel-image noSelect hover-cursor" src={data.media_image.url} id={data.media_image.id}
                                                            style={{width: data.media_coverage+"%", height: "auto", zIndex: 9 + i}}
                                                            // style={{height: "calc("+ toSubstract + ")"}}
                                                            onMouseUp={(e) => this.deleteImage(e)}
                                                            onMouseOver={(e) => this.addFontItalic(e)}
                                                            onMouseLeave={(e) => this.removeFontItalic(e)}
                                                            // onLoad={(e) => this.resizeImage(e)}
                                                        />
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div id={"image_div"+data.media_image.id} key={i}>
                                                        <div className="image_title_wrap" style={{zIndex: 10 + i}}>
                                                            <p>{data['media_image'].title}</p>
                                                        </div>
                                                        <img key={i} alt="" className="panel-image noSelect hover-cursor" src={data.media_image.url} id={data.media_image.id}
                                                            style={{height: data.media_coverage+"%", width: "auto", zIndex: 9 + i}}
                                                            // style={{height: "calc("+ toSubstract + ")"}}
                                                            onMouseUp={(e) => this.deleteImage(e)}
                                                            onMouseOver={(e) => this.addFontItalic(e)}
                                                            onMouseLeave={(e) => this.removeFontItalic(e)}
                                                            // onLoad={(e) => this.resizeImage(e)}
                                                        />
                                                    </div>
                                                )
                                            }

                                        })
                                    }

                                </div>
                            }

                            <div id="plus-image" ref="plusImage" className="panel-image icon-image noSelect">
                                <div className="cross-div h-cross"></div>
                                <div className="cross-div w-cross"></div>
                            </div>
                            <div id="close-image" ref="closeImage" className="panel-image icon-image noSelect cross-kill">
                                <div className="cross-div h-cross"></div>
                                <div className="cross-div w-cross"></div>
                            </div>
                        </Col>
                        <Col lg={5} md={5} sm={12} xs={12} id="panels-item--two-plus-three" style={style}>
                            <Row>
                                <Col id="panels-item--two" className="panels-item panels-item--two" lg={96} md={96} sm={6} xs={6} onClick={(item) => this.showColumn(item)} style={borderRight}>
                                    {
                                        this.props.postData.acf &&
                                        <div >

                                            {

                                                this.props.postData.acf.bio &&
                                                this.props.postData.acf.bio.map((data, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <h2 className="sub-title bio-year">{data.bio_year}</h2>
                                                            <p className="panelPStyle bio-description">{data.bio_description}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                this.props.postData.acf.exhibitions &&
                                                <h1 className="h1HeadingStyle">Exhibitions</h1>}
                                            {
                                                this.props.postData.acf.exhibitions &&
                                                this.props.postData.acf.exhibitions.map((data, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <h2 className="sub-title exhibition-year">{data.exhibition_year}</h2>
                                                            <p className="panelPStyle exhibition-title">{data.exhibition_title}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </Col>
                                <Col id="panels-item--three" className="panels-item panels-item--three action-panel" lg={96} md={96} sm={6} xs={6} style={style}>
                                    <h1 className="h1HeadingStyle" onClick={this.showSecondColumn} >{this.props.postData.title.rendered}</h1>
                                    <div className="hovering-div">
                                        {
                                            this.props.postData.acf.media &&
                                            this.props.postData.acf.media.map((data, i) => {
                                                return (
                                                    <div key={i} className="width-fit-content hover-cursor2" ref={data.media_image.id} onClick={() => this.promptImage(data.media_image.id)}>
                                                        <h2 className="sub-title">{data.media_title}</h2>
                                                        <p className="panelPStyle">{data.media_description}</p>
                                                    </div>
                                                );
                                            })
                                        }

                                    </div>
                                    {
                                        typeof(this.props.postData.acf.link_for_website) !== 'undefined' && this.props.postData.acf.link_for_website !== '' ?
                                            <a className="aTagStyle" target="_blank" rel="noopener noreferrer" href={this.props.postData.acf.link_for_website}>[link for website]</a>
                                            :
                                            ''
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <PanelFooter history={this.props.history}
                    urlPath={this.props.categoryName}
                    istoArtistCategory={true}
                    onClickLogo={() => this.handleRedirect("homePage")}
                    onClikHeaderCategory={() => this.handleRedirect("sectionPage")} >
                </PanelFooter>

            </div>
        );
    }
}

function mapStateToProps(state) {
    if (state.postData.categories) {
        let categoryNumber = state.postData.categories[0];
        state.categoriesData.map((data, index) => {
            if (data.id === categoryNumber) {
                state.categoryName = data.slug;
            }
            return true;
        })
    }
    if (state.postData.acf) {
        let temp_media = [];
        state.postData.acf.media.map((data, index) => {
            if (data.media_image) {
                temp_media.push(data);
            }
            return true;
        })
        state.postData.acf.media = temp_media;
    }

    if (state.categoriesData.length !== 0) {
        state.categoriesData.map((data, index) => {
            if (data.slug === state.categoryName) {
                state.categoryId = data.id;
                state.categoryCount = data.count;
            }
            return true;
        })
    }
    if (state.categoryCount) {
        const categoryCount = state.categoryCount;
        if (categoryCount > 45) {
            state.lineWidth = "1px";
        }
        else if (categoryCount > 40) {
            state.lineWidth = "2px";
        }
        else if (categoryCount > 30) {
            state.lineWidth = "3px";
        }
        else if (categoryCount > 25) {
            state.lineWidth = "4px";
        }
        else if (categoryCount > 20) {
            state.lineWidth = "5px";
        }
        else if (categoryCount > 15) {
            state.lineWidth = "6px";
        }
        else if (categoryCount > 10) {
            state.lineWidth = "7px";
        }
        else if (categoryCount > 6) {
            state.lineWidth = "8px";
        }
        else if (categoryCount > 3) {
            state.lineWidth = "9px";
        }
        else {
            state.lineWidth = "10px";
        }

    }

    return {
        postData: state.postData,
        categoriesDataContent: state.categoriesDataContent,
        categoryName: state.categoryName,
        categoriesData: state.categoriesData,
        categoryId: state.categoryId,
        categoryCount: state.categoryCount,
        lineWidth: state.lineWidth,
        frontUrl: state.frontUrl
    }
}

const mapDispatchToProps = {
    getPostDataBySlug: baadActions.getPostDataBySlug,
    requestForGetCategoriesData: baadActions.requestForGetCategoriesData,
    getPageDataBySlug: baadActions.getPageDataBySlug,
}

export default connect(mapStateToProps, mapDispatchToProps)(artist)
