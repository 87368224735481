import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from "react-redux";
import baadActions from '../../redux/actions';
import Button from '../../components/Button/Button'

import './randomTeaser.scss';
import constants from "../../utils/appConstant";
import {Link} from "react-router-dom";
class Randomteaser extends Component {
    constructor(props){
        super(props);
        this.handler = null;
    }

    async componentDidMount() {
        let paramsName = this.props.history.location.pathname.slice(1);
        await new Promise(resolve => {
            this.props.getPageDataBySlug(paramsName, resolve)
        });
    }

    async componentDidUpdate(prevProps) {
        const hoverDom = this.refs.randomTip;

        if(hoverDom){
            const soundBox = hoverDom.querySelector('#sound-box');
            hoverDom.addEventListener('mousemove', ()=>{
                soundBox.classList.add("show-item");
                clearTimeout(this.handler);
                this.handler = setTimeout(function(){
                    soundBox.classList.remove('show-item');
                }, 5000)
            })
        }
        if (this.props.history.action === "POP" && String(prevProps.pageData) === '') {
            let paramsName = this.props.history.location.pathname.slice(1);
            await new Promise(resolve => {
                this.props.getPageDataBySlug(paramsName, resolve)
            });
        }
    }

    switchSound = () => {
        const soundBox = document.getElementById('sound-box');
        const anachronicVideo = document.getElementById('anachronic-video');
        if(soundBox.classList.contains('toggle-mute--muted')) {
            soundBox.classList.remove('toggle-mute--muted');
            anachronicVideo.muted = !anachronicVideo.muted;
        } else {
            soundBox.classList.add('toggle-mute--muted');
            anachronicVideo.muted = !anachronicVideo.muted;
        }
    }

    render() {
        if(!this.props.pageData || this.props.pageData.length === 0){
            return null;
        }

        return (
            <div id="ondivhover" className="page enter-Left exit-Left randomTeaser-page">
                <Row>
                    <div ref="randomTip" className="randomTip">
                        <div id="sound-box" data-js="toggle-mute" data-cursor="full" className="toggle-mute to-hide toggle-mute--muted" onClick={() => this.switchSound()}></div>
                        {
                            this.props.pageData.acf.Video?
                            (
                                <video id="anachronic-video" data-js="anachronic-video" loop muted autoPlay playsinline="playsinline" className="media-style">
                                    <source src={this.props.pageData.acf.Video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img src={this.props.pageData.acf.Image} className="media-style" alt="" />
                            )
                        }
                    </div>
                    <div className="randomTeaser__btn">
                        <Button to={constants.INTRO_URL === 'intro' ? '/gallery' : '/human-activities'} btnText={window.innerWidth <= 667 ? 'Visit Exhibition' : 'Enter Exhibition'}/>
                    </div>
                </Row>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        pageData: state.pageData,
    } 
}

const mapDispatchToProps = {
    getPageDataBySlug: baadActions.getPageDataBySlug,
}

export default connect(mapStateToProps, mapDispatchToProps)(Randomteaser);
