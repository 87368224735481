import React, { Component } from 'react';
import './homeLink.scss';
import $ from 'jquery';
import constants from "../../utils/appConstant";

class HomeLink extends Component {
    constructor(props) {
        super(props);
        if (this.props.history.location.pathname === "/" + constants.INTRO_URL) {
            this.state = {tooltipText: "EXIT", flyingDotColor: "rgba(255,0,255,1)"};
        }else{
            this.state = {tooltipText: "INTRO", flyingDotColor: "rgba(0,0,0,1)"};
        }

        // const urlString = this.props.frontUrl;
        // if (urlString === '/random-teaser') {
        //     this.state = {flyingDotColor: "rgba (255,0,255,1)"}
        //     console.log("misiek")
        //     console.log("17777: "+ this.state.flyingDotColor)
        // } else {
        //     this.state = {flyingDotColor: "rgba (0,0,0,1)"}
        // }
    }
    componentDidMount() {
        if (this.refs.homeLink) {

            pulse();

            function makeNewPosition() {
                var h = $(window).height() - 50;
                var w = $(window).width() - 50;
                var nh = Math.floor(Math.random() * h);
                var nw = Math.floor(Math.random() * w);
                return [nh, nw];
            }

            function animateDiv() {

                var newq = makeNewPosition();
                var oldq = $('#homeLink').offset();
                /* @FIXME: this is a workaround, not a correct handling */
                if(typeof oldq !== "undefined") {
                    var speed = calcSpeed([oldq.top, oldq.left], newq);

                    $('#homeLink').animate({top: newq[0], left: newq[1]}, speed, function () {
                        animateDiv();
                    });
                }

            };

            function pulse() {
                /* eslint no-unused-expressions: "off" */
                var actors = {};
                actors.actor_2 = { node: document.getElementById("SVG-Circus-ee1f424e-9212-879f-5960-c263a90467c2").getElementById("actor_2"), type: "circle", cx: 18, cy: 18, dx: 63, dy: 8, opacity: 1 };
                actors.actor_1 = { node: document.getElementById("SVG-Circus-ee1f424e-9212-879f-5960-c263a90467c2").getElementById("actor_1"), type: "circle", cx: 18, cy: 18, dx: 49, dy: 8, opacity: 1 };
                actors.actor_3 = { node: document.getElementById("SVG-Circus-ee1f424e-9212-879f-5960-c263a90467c2").getElementById("actor_3"), type: "circle", cx: 18, cy: 18, dx: 35, dy: 8, opacity: 1 };
                actors.actor_4 = { node: document.getElementById("SVG-Circus-ee1f424e-9212-879f-5960-c263a90467c2").getElementById("actor_4"), type: "circle", cx: 18, cy: 18, dx: 21, dy: 8, opacity: 1 };

                var tricks = {};
                tricks.trick_1 = (function (_, t) { t = (function (t) { return 0 === t ? 0 : 1 === t ? 1 : (t /= .5) < 1 ? .5 * Math.pow(2, 10 * (t - 1)) : .5 * (-Math.pow(2, -10 * --t) + 2)})(t) % 1; t = 0 > t ? 1 + t : t; var i; i = 0.14 >= t ? 1.2 - (1.2 - 1) / 0.14 * t : t >= 0.31 ? 1 + (t - 0.31) * ((1.2 - 1) / (1 - 0.31)) : 1; var a = _._tMatrix, r = -_.cx * i + _.cx, x = -_.cy * i + _.cy, n = a[0] * i, c = a[1] * i, M = a[2] * i, g = a[3] * i, f = a[0] * r + a[2] * x + a[4], m = a[1] * r + a[3] * x + a[5]; _._tMatrix[0] = n; _._tMatrix[1] = c; _._tMatrix[2] = M; _._tMatrix[3] = g; _._tMatrix[4] = f; _._tMatrix[5] = m });

                var scenarios = {};
                scenarios.scenario_1 = {
                    actors: ["actor_1", "actor_2", "actor_3"],
                    tricks: [{ trick: "trick_1", start: 0, end: 1 }, { trick: "trick_1", start: 0.00, end: 1.00 }],
                    startAfter: 0,
                    duration: 1700,
                    actorDelay: 100,
                    repeat: 0,
                    repeatDelay: 200
                };
                scenarios.scenario_2 = {
                    actors: ["actor_4"],
                    tricks: [{ trick: "trick_1", start: 0, end: 1 }, { trick: "trick_1", start: 0.00, end: 1.00 }],
                    startAfter: 0,
                    duration: 1600,
                    actorDelay: 100,
                    repeat: 0,
                    repeatDelay: 200
                };

                var _reqAnimFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.oRequestAnimationFrame
                var fnTick = function (t) {
                    var r, a, i, e, n, o, s, c, m, f, d, k, w;
                    for (c in actors) {
                        actors[c]._tMatrix = [1, 0, 0, 1, 0, 0]
                    }
                    for (s in scenarios) for (o = scenarios[s], m = t - o.startAfter, r = 0, a = o.actors.length; a > r; r++) {
                        if ((i = actors[o.actors[r]], i && i.node && i._tMatrix)) {
                            for (f = 0, m >= 0 && (d = o.duration + o.repeatDelay, o.repeat > 0 && m > d * o.repeat && (f = 1), f += m % d / o.duration), e = 0, n = o.tricks.length; n > e; e++) {
                                k = o.tricks[e];
                                w = (f - k.start) * (1 / (k.end - k.start));
                                tricks[k.trick] && tricks[k.trick](i, Math.max(0, Math.min(1, w)));
                                m -= o.actorDelay
                            }
                        }
                    }
                    for (c in actors)
                        (i = actors[c], i && i.node && i._tMatrix && i.node.setAttribute("transform", "matrix(" + i._tMatrix.join() + ")"))
                            _reqAnimFrame(fnTick)
                };
                _reqAnimFrame(fnTick);
            }

            function calcSpeed(prev, next) {
                var x = Math.abs(prev[1] - next[1]);
                var y = Math.abs(prev[0] - next[0]);
                var greatest = x > y ? x : y;
                var speedModifier = 0.1;
                var speed = Math.ceil(greatest / speedModifier);
                return speed;
            }
            if ($('#homeLink').offset()) {
                animateDiv();
            }
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.isCategoryPage ? (
                        <div ref="homeLink" id="homeLink" className="cursorStyle flyingDot"
                            onClick={() => this.props.clickHomeLink()}
                            onMouseOver={(e) => this.props.onOver(e)}
                            onMouseLeave={(e) => this.props.onLeave(e)}>

                            <svg id="SVG-Circus-ee1f424e-9212-879f-5960-c263a90467c2" version="1.1" preserveAspectRatio="xMidYMid meet">
                                <circle id="actor_4" cx="18" cy="18" r="2" opacity="1" fill={this.state.flyingDotColor} fillOpacity="1" stroke={this.state.flyingDotColor} strokeWidth="1" strokeOpacity="1" strokeDasharray="">
                                </circle>

                                <circle id="actor_3" cx="18" cy="18" r="5" opacity="1" fill="rgba(255,255,255,0)" fillOpacity="1" stroke={this.state.flyingDotColor} strokeWidth="2" strokeOpacity="1" strokeDasharray="">
                                </circle>

                                <circle id="actor_1" cx="18" cy="18" r="8" opacity="1" fill="rgba(255,255,255,0)" fillOpacity="1" stroke={this.state.flyingDotColor} strokeWidth="1" strokeOpacity="1" strokeDasharray="">
                                </circle>

                                <circle id="actor_2" cx="18" cy="18" r="11" opacity="1" fill="rgba(255,255,255,0)" fillOpacity="1" stroke={this.state.flyingDotColor} strokeWidth="1" strokeOpacity="1" strokeDasharray="">
                                </circle>
                            </svg>
                            <div className="innerTooltip" data-js="random-element-inner">
                                <span className="outerThreeTooltip">{this.state.tooltipText}</span>
                            </div>

                        </div>
                    ) : (
                        <div ref="homeLink" id="homeLink" className="cursorStyle flyingDot"
                            onClick={() => this.props.clickHomeLink()}>

                            <svg id="SVG-Circus-ee1f424e-9212-879f-5960-c263a90467c2" version="1.1" preserveAspectRatio="xMidYMid meet">
                                <circle id="actor_4" cx="18" cy="18" r="2" opacity="1" fill={this.state.flyingDotColor} fillOpacity="1" stroke={this.state.flyingDotColor} strokeWidth="1" strokeOpacity="1" strokeDasharray="">
                                </circle>

                                <circle id="actor_3" cx="18" cy="18" r="5" opacity="1" fill="rgba(255,255,255,0)" fillOpacity="1" stroke={this.state.flyingDotColor} strokeWidth="2" strokeOpacity="1" strokeDasharray="">
                                </circle>

                                <circle id="actor_1" cx="18" cy="18" r="8" opacity="1" fill="rgba(255,255,255,0)" fillOpacity="1" stroke={this.state.flyingDotColor} strokeWidth="1" strokeOpacity="1" strokeDasharray="">
                                </circle>

                                <circle id="actor_2" cx="18" cy="18" r="11" opacity="1" fill="rgba(255,255,255,0)" fillOpacity="1" stroke={this.state.flyingDotColor} strokeWidth="1" strokeOpacity="1" strokeDasharray="">
                                </circle>
                            </svg>
                            <div className="innerTooltip" data-js="random-element-inner">
                                <span className="outerThreeTooltip">{this.state.tooltipText}</span>
                            </div>

                        </div>
                    )
                }
            </div>

        );
    }
}

export default HomeLink;
