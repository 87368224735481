import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from "react-redux";
import baadActions from '../../redux/actions';

import './videoTemplate.scss';

import HomeLink from '../HomeLink/homeLink';
import PanelFooter from '../panelFooter/panelFooter';

class videoTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromRandomTeaser: false
        }
        this.handler = null;
    }

    componentDidMount() {
        document.getElementById('footer_content').style.display ="none";
    }

    async componentDidUpdate(prevProps) {
        const hoverDom = this.refs.randomTip;

        if(hoverDom){
            const showDom = hoverDom.querySelector('#showDiv');
            const soundBox = hoverDom.querySelector('#sound-box');
            const playBox = hoverDom.querySelector('#play-box');
            hoverDom.addEventListener('mousemove', ()=>{
                showDom.classList.add("show-item");
                soundBox.classList.add("show-item");
                playBox.classList.add("show-item");
                clearTimeout(this.handler);
                this.handler = setTimeout(function(){
                    showDom.classList.remove('show-item');
                    soundBox.classList.remove('show-item');
                    playBox.classList.remove('show-item');
                }, 5000)
            })
        }
        if ( this.props.history.action === "POP" && prevProps.pageData.length === 0) {
            // let paramsName = this.props.history.location.pathname.slice(1);
            let paramsName = "random-teaser";
            await new Promise(resolve => {
                this.props.getPageDataBySlug(paramsName, resolve)
            });
        }
    }

    clickHomeLink = async() => {
        document.getElementsByClassName('page')[0].classList.remove('exit-Down');
        document.getElementsByClassName('page')[0].classList.add('exit-Right');
        
        await new Promise(resolve => {
            this.props.getPageDataBySlug('random-teaser', resolve);
        });
        let currentPath = this.props.history.location.pathname;
        this.props.history.push({
            pathname: '/random-teaser',
            state: {beforePath: currentPath}
        });
    }

    handleRedirectHome = (e) => {
        // document.getElementsByClassName('page')[0].classList.remove('exit-Down');
        // document.getElementsByClassName('page')[0].classList.add('exit-Right');
        this.props.history.push({
            pathname: '/',
            state: {fromEventPage: true}
        });
    }

    handleRedirectHuman = () => {
        document.getElementsByClassName('headerDiv')[0].style.padding = "0px 10px 58px";
        document.getElementsByClassName('panel-footer')[0].style.display="none";
        this.props.history.push({
            pathname: '/human-activities',
            state: {fromEventPage: true}
        })
    }

    switchSound = () => {
        const soundBox = document.getElementById('sound-box');
        const anachronicVideo = document.getElementById('anachronic-video');
        if(soundBox.classList.contains('toggle-mute--muted')) {
            soundBox.classList.remove('toggle-mute--muted');
            anachronicVideo.muted = !anachronicVideo.muted;
        } else {
            soundBox.classList.add('toggle-mute--muted');
            anachronicVideo.muted = !anachronicVideo.muted;
        }
    }
    switchPlay = () => {
        const playBox = document.getElementById('play-box');
        const anachronicVideo = document.getElementById('anachronic-video');
        if(playBox.classList.contains('paused')) {
            playBox.classList.remove('paused');
            anachronicVideo.pause();
        } else {
            playBox.classList.add('paused');
            anachronicVideo.play();
        }
    }

    render() {
        if(!this.props.pageData || this.props.pageData.length === 0){
            return null;
        }

        return (
            <div className={"whatever-class video-template page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>
                <HomeLink history={this.props.history} clickHomeLink={()=>this.clickHomeLink()}></HomeLink>
                <div className="headerDiv headerDiv-artist">
                    <Row className="main-border">
                        <div ref="randomTip" className="randomTip">
                            <div id="sound-box" data-js="toggle-mute" data-cursor="full" className="toggle-mute to-hide toggle-mute--muted" onClick={() => this.switchSound()}></div>
                            <div id="play-box" 
                                data-js="toggle-play" 
                                data-cursor="full" 
                                className="toggle-play paused" 
                                onClick={() => this.switchPlay()}
                            ></div>
                            {
                                this.props.pageData.acf.Video?
                                (
                                    <video id="anachronic-video" data-js="anachronic-video" loop muted autoPlay className="media-style" autostart="false">
                                        <source src={this.props.pageData.acf.Video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> 
                                ) : (
                                    <img src={this.props.pageData.acf.Image} className="media-style" alt="" />
                                )
                            }

                            <div id="showDiv" className="page-descrition">
                                <h1 className="H1StyleforRandomPage">{this.props.pageData.acf.title}</h1>
                                <h1 className="H1StyleforRandomPage">{this.props.pageData.acf.year}</h1><br />
                                <h3 className="H3StyleforRandomPage">{this.props.pageData.acf.event.replace(/<\/?[^>]+(>|$)/g, "")}</h3>
                            </div>
                        </div>
                    </Row>
                </div>
                <PanelFooter history={this.props.history} urlPath="humanActivities" 
                    isHumanPostPage={true}
                    onClickLogo={(e) => this.handleRedirectHome(e)}
                    onClikHeaderCategory={() => this.handleRedirectHuman()}>
                </PanelFooter>
            </div>
            
        )
    }
}

function mapStateToProps(state) {
    return {
        pageData: state.pageData,
    } 
}

const mapDispatchToProps = {
    getPageDataBySlug: baadActions.getPageDataBySlug,
}

export default connect(mapStateToProps, mapDispatchToProps)(videoTemplate);