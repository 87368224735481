import Home from "./view/home/home";
import artistCategory from "./view/artistCategory/artistCategory";
import artist from "./view/artist/artist";
import RandomTeaser from "./view/randomTeaser/randomTeaser";
import Humanactivities from "./view/humanActivities/humanActivities";
// import TestPost from "./view/testPost/testPost";
import HumanPost from "./view/humanPost/humanPost";
import videoTemplate from "./view/videoTemplate/videoTemplate";
import constants from "./utils/appConstant";

export default [
  // {
  //   path: "/",
  //   exact: true,
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: "/c/:id",
    name: 'artistCategory',
    component: artistCategory,
  },
  {
    path: "/" + constants.INTRO_URL,
    name: 'random-teaser',
    exact: true,
    component: RandomTeaser,
  },
  {
    path: constants.INTRO_URL === 'intro' ? "/gallery" : '/human-activities',
    name: 'humanActivities',
    exact: true,
    component: Humanactivities,
  },
  {
    path: '/',
    name: 'random-teaser',
    exact: true,
    component: RandomTeaser,
  },
  {
    path: "/h/:id",
    name: 'human-post',
    exact: true,
    component: HumanPost,
  },
  // {
  //   path: "/human-activities-test-post",
  //   name: 'human-activities-test-post',
  //   exact: true,
  //   component: TestPostOne,
  // },
  // {
  //   path: "/human-activities-test-post-1",
  //   name: 'human-activities-test-post-1',
  //   exact: true,
  //   component: TestPostOne,
  // },
  {
    path: "/video-template",
    name:'videoTemplate',
    exact: true,
    component: videoTemplate,
  },
];
