import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware , compose} from 'redux';
import { Provider } from 'react-redux';
import { logger } from 'redux-logger';
import reducer from './redux/reducers';

import rootSaga from './redux/saga';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(sagaMiddleware,logger),
));

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <BrowserRouter>
   <Provider store={store}>
      <App />
   </Provider>
  </BrowserRouter>,
    document.getElementById('root'),
);

serviceWorker.unregister();