import { all, takeEvery, fork, put } from 'redux-saga/effects';
import constants from '../../utils/constants';
import actions from '../actions/index';
import HttpProcessor from '../../utils/httpProcessor';




function* requestForGetCategories() {
  // let httpProcessor = new HttpProcessor();
  // const json = yield httpProcessor.getCategories();
  // console.log("---requestForGetCategories---", json);
  // yield put({ type: actions.REQUEST_FOR_CATEGORIES, categories: json });

  yield takeEvery(actions.REQUEST_FOR_CATEGORIES, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getCategories();
    yield put({ type: actions.SET_CATEGORIES_RESULT, categories: json });
  })

}
function* setCetogoriesData() {
  let httpProcessor = new HttpProcessor();
  const json = yield httpProcessor.getCategories();
  //console.log("---setCetogoriesData---", json);
  yield put({ type: actions.SET_CATEGORIES_RESULT, categories: json });
}
function* requestForGetCategoriesData() {
  yield takeEvery(actions.REQUEST_FOR_CATEGORIES_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getCategoriesData(action.id, action.count);
    yield put({ type: constants.CATEGORIES_DATA, categoriesData: json });
  })
}

function* getPostData() {
  yield takeEvery(actions.GET_POST_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getPost(action.postId);
    yield put({ type: constants.POST_DATA, postData: json });
  })
}
function* getPostDataBySlug() {
  yield takeEvery(actions.GET_POST_DATA_BYSLUG, function* (action, resolve) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getPostBySlug(action.slug);
    yield put({ type: constants.POST_DATA, postData: json[0] });
    if(action){
    action.resolve('done');
    }
  })
}
function* getHumanPostDataBySlug() {
  yield takeEvery(actions.GET_HUMAN_POST_DATA_BYSLUG, function* (action, resolve) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getPostBySlug(action.slug);
    yield put({ type: constants.HUMAN_POST_DATA, humanPostData: json[0] });
    if(action){
    action.resolve('done');
    }
  })
}
function* getPageDataBySlug() {
  yield takeEvery(actions.GET_PAGE_DATA_BYSLUG, function* (action, resolve) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getPageBySlug(action.slug);
    yield put({ type: constants.PAGE_DATA, pageData: json[0] });
    if(action){
      action.resolve('done');
    }
  })
}
function* getHumanData() {
  yield takeEvery(actions.GET_HUMAN_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getHumanData();
    yield put({ type: constants.HUMAN_DATA, humanData: json });
  })
}

 

  export default function* rootSaga() {
    yield all([
     // fork(watchGetCategories),
      fork(requestForGetCategories),
      fork(setCetogoriesData),
      fork(requestForGetCategoriesData),
      fork(getPostData),
      fork(getPostDataBySlug),
      fork(getHumanPostDataBySlug),
      fork(getPageDataBySlug),
      fork(getHumanData)
    ]);
  }