import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import baadActions from '../../redux/actions/';
import './panelFooter.scss';
import ExhibitionPost from "../exhibitionPost/exhibitionPost";
import constants from "../../utils/appConstant";

class PanelFooter extends Component {
     constructor(props) {
          super(props);
          let exhibitionName = '';
          if (props.history.location.state) {
               if (props.history.location.state.toExhibition) {
                    exhibitionName = props.history.location.state.exhibitionName;
               }
          }
          this.state = {
               pathName: '',
               exhibitionName: exhibitionName,
               showExhibitionModal: false
          }
          this.showModal = this.showModal.bind(this);
          this.hideModal = this.hideModal.bind(this);
          this.goBack = this.goBack.bind(this);
     }
     showModal = () => {
          this.setState({ showExhibitionModal: true });
     };
     hideModal = () => {
          this.setState({ showExhibitionModal: false });
     };
     goBack(){
          this.props.history.goBack();
     };
     static getDerivedStateFromProps(props, state) {
          if (props.urlPath) {
               if (props.urlPath === 'humanActivities') {
                    return { pathName: 'HUMAN-ACTIVITIES' };
               } else {
                    // return { pathName: props.urlPath.split('-').join(' ') };
                    return { pathName: props.urlPath };
               }
          } else {
               var segment_array = props.history.location.pathname.split( '/' );
               var last_segment = segment_array.pop();
               // return { pathName: last_segment.split('-').join(' ') };
               return { pathName: last_segment };
          }
     }

     render() {
          const footerSubTitle = document.querySelector('[data-js="footer-sub-title"]');
          const body = document.querySelector('[data-js="body"]');
          const navigation = document.querySelector('[data-js="navigation"]');
          const homeLink = document.querySelector('[data-js="home-link"]');
          const artistCategoryPage = document.querySelector(".artistCategory-page");
          const activeFormStep = this.props.activeFormStep + 1;
          let exhibitionName = ""

          if (homeLink) {
               const links = homeLink.getElementsByTagName('*');
               if (navigation && navigation.getBoundingClientRect().right - 160 < window.innerWidth / 2) {
                    homeLink.classList.add('short');
                    toggleCursorFull(links, true);
               } else if (body && body.getAttribute('data-category-page') !== '') {
                    homeLink.classList.add('short');
                    footerSubTitle.innerHTML = body.getAttribute('data-category-page');
                    footerSubTitle.setAttribute('href', `${body.getAttribute('data-category-page-slug')}`);
               } else {
                    homeLink.classList.remove('short');
                    //footerSubTitle.innerHTML = '';
                    toggleCursorFull(links, false);
               }
               if (artistCategoryPage) {
                    homeLink.classList.add('short');
               }
          }

          this.props.categoriesData && this.props.categoriesData.forEach(category => {
               const {id, name} = category;
               if (constants.CATEGORY_ID === id) {
                    exhibitionName = name
               }
          })

          return (
              <>
                   <div className={this.props.isContactOpen ? 'd-none d-md-block panel-footer' : 'panel-footer'}>
                        <Row>
                             <Col sm={4} >
                                  {
                                       <div className="panel-footer__title-wrapper">
                                            {!this.props.isContactOpen &&
                                            <span className="footer__dot"/>
                                            }
                                            {this.props.isContactOpen &&
                                            <button className="panelFooter-icon panelFooter-icon--left"  onClick={this.props.handler}>≤</button>
                                            }
                                            <div className="panel-footer__title">
                                                 <h1
                                                     data-js="home-link"
                                                     className={this.props.isContactOpen ? 'contact-form__heading' : 'headingForRedirect'}
                                                     onClick={(e) => this.props.onClickLogo(e)}
                                                     onMouseOver={this.props.mouseIn}
                                                     onMouseOut={this.props.mouseOut}
                                                 >
                                                      {/*<span>B</span>*/}
                                                      {/*<span>A</span>*/}
                                                      {/*<span>A</span>*/}
                                                      {/*<span>D</span>*/}
                                                      {!this.props.isContactOpen ? exhibitionName : 'PURCHASING THE ARTWORK'}
                                                      {/*{this.state.exhibitionName}*/}
                                                 </h1>
                                                 <span data-cursor="full" className="footer__subtitle animsition-link" data-animsition-out-class="fade-out-right-lg"
                                                       data-js="footer-sub-title">&nbsp;</span>

                                                 {
                                                      this.props.istoArtistCategory ? (
                                                          <h2 className="headingForRedirect"
                                                              onClick={() => this.props.onClikHeaderCategory()}
                                                              onMouseOver={this.props.mouseIn}
                                                              onMouseOut={this.props.mouseOut}
                                                          >
                                                               {this.props.isContactOpen && `Step ${activeFormStep}/3`}
                                                          </h2>
                                                      ) : (
                                                          this.props.isHumanPostPage ? (
                                                              <h2
                                                                  className="headingForRedirect"
                                                                  onClick={() => this.props.onClikHeaderCategory()}
                                                                  onMouseOver={this.props.mouseIn}
                                                                  onMouseOut={this.props.mouseOut}
                                                              >
                                                                   {this.props.isContactOpen && `Step ${activeFormStep}/3`}
                                                              </h2>
                                                          ) : (
                                                              <h2
                                                                  className="headingForRedirect"
                                                                  onClick={() => this.props.history.push('/' + this.props.urlPath)}
                                                                  onMouseOver={this.props.mouseIn}
                                                                  onMouseOut={this.props.mouseOut}
                                                              >
                                                                   {this.props.isContactOpen && `Step ${activeFormStep}/3`}
                                                              </h2>
                                                          )
                                                      )
                                                 }
                                            </div>
                                       </div>
                                  }
                             </Col>
                        </Row>
                        {!this.props.isContactOpen &&
                        <button className="panelFooter-icon" onClick={(e) => this.props.onClickLogo(e)}>≤</button>
                        }
                   </div>
                   <ExhibitionPost show={this.state.showExhibitionModal} handleClose={this.hideModal} />
              </>
          );
     }
}
function mapStateToProps(state) {
     if (state.categoriesData.length !== 0) {
          state.categoriesData.map((data, index) => {
               if (data.slug === state.categoryName) {
                    state.categoryId = data.id;
                    state.categoryCount = data.count;
               }
               return true;
          })
     }
     return {
          categoriesData: state.categoriesData,
          categoryId: state.categoryId,
          categoryCount: state.categoryCount,
     }
}

function toggleCursorFull(links, isFull) {
     [].map.call(links, (link) => {
          if (isFull) {
               link.setAttribute('data-cursor', 'full');
          } else {
               link.setAttribute('data-cursor', '');
          }
     });
}

export function initScrollBehaviour() {
     const homeLinkElement = document.querySelector('[data-js="home-link"]');
     const footerSubTitle = document.querySelector('[data-js="footer-sub-title"]');
     const links = homeLinkElement.getElementsByTagName('*');

     homeLinkElement.addEventListener('click', () => {
          if (window.location.pathname === '/') {
               // $('#lines').scrollTo('.navigation', 250, { });
               // $('#lines').stop().animate({
               //     scrollLeft: 0,
               // }, SCROLLING_TIME - 400, 'easeInOutQuart');
               // setTimeout(() => {
               document.body.setAttribute('data-category', '');
               // homeLinkElement.classList.remove('short');
               toggleCursorFull(links, false);
               footerSubTitle.innerHTML = '';
               // }, SCROLLING_TIME);
          } else {
               window.location = '/';
          }
     }, false);

     // scrollVert();
}

const mapDispatchToProps = {
     requestForGetCategoriesData: baadActions.requestForGetCategoriesData,
     mouseIn: baadActions.mousePosIn,
     mouseOut: baadActions.mousePosOut
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelFooter);
