import React from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import routes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from 'history';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Footer from './view/panelFooter/Footer';
import {Redirect, Router, withRouter} from 'react-router';
import { connect } from 'react-redux';
import {compose} from 'redux';
import constants from "./utils/appConstant";

require('dotenv').config()

const history = createBrowserHistory();

function App(props) {
  const location = useLocation();

    return (
    <div>
       {/*<Router>*/}
        <TransitionGroup>
          <CSSTransition
            timeout={{enter: 700, exit: 700}}
            key={location.key}
            classNames="page">
              <>
                <Switch location={location}>
                  {routes.map((route, index) => {
                    return (<Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                      history={history}
                    // state={route.state}
                    />)
                  })}
                  <Redirect to="/" />
                </Switch>
                <Route component={Footer} history={history} />
              </>
          </CSSTransition>
        </TransitionGroup>
         {/*</Router>*/}
    </div>
  );
}

const mapStateToProps = state => {
  const frontUrl = state.frontUrl;
  if(frontUrl ===''){
    state.lineWidth = "1px";
  }
  return {
    lineWidth: state.lineWidth,
    mousePos: state.mousePos,
    frontUrl: state.frontUrl
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps)
)(App);
