
import baadActions from '../actions/index';
import constants from '../../utils/constants'
const initialState = {
    categories: [],
    categoryName: "",
    categoriesData: [],
    categoriesDataContent: [],
    postData: [],
    pageData: [],
    totalCount: [], 
    humanData: [], 
    frontUrl: '',
    mousePos: '',
    humanPostData:[]
};

export default function baadReducer(state = initialState, action) {
    switch (action.type) {
        case baadActions.REQUEST_FOR_CATEGORIES:
           
            return {
                ...state,
                categories: action.categories
            }
        case baadActions.CATEGORIES_NAME:

                return {
                    ...state,
                    categoryName: action.name
            }
        case baadActions.SEND_FRONT_URL:

                return {
                    ...state,
                    frontUrl: action.payload,
            }
        case baadActions.MOUSE_IN:

                return {
                    ...state,
                    mousePos: action.payload
            }
        case baadActions.MOUSE_OUT:

                return {
                    ...state,
                    mousePos: action.payload
            }
        case constants.CATEGORIES_DATA:
               
            return {
                ...state,
                categoriesDataContent: action.categoriesData
            }
        case constants.POST_DATA:
            return {
                ...state,
                postData: action.postData
            }
        case constants.HUMAN_POST_DATA:
            return {
                ...state,
                humanPostData: action.humanPostData
            }

        case baadActions.TOTAL_LINK_COUNTER:
            return {
                ...state,
                totalCount: action.count
            }
        case baadActions.SET_CATEGORIES_RESULT:
            return {
                ...state,
                categoriesData: action.categories
            }
        case constants.PAGE_DATA:
            return {
                ...state,
                pageData: action.pageData
            }
        case constants.HUMAN_DATA:
            return {
                ...state,
                humanData: action.humanData
            }
        default:
            return state
    }
}
